<template>
  <div class="pt-8">
    <v-row>
      <v-col cols="12" sm="9" md="6" lg="5">
        <v-form ref="form" @submit.stop.prevent>
          <v-text-field
            v-model.trim="editedDealer.name"
            :rules="rules.name"
            :label="$t('dealer.edit.name')"
            tabindex="1"
            clearable
            autofocus
          ></v-text-field>
          <v-text-field
            v-model.trim="editedDealer.phone"
            :rules="rules.phone"
            :label="$t('dealer.edit.phone')"
            type="tel"
            tabindex="1"
            clearable
          ></v-text-field>
          <v-text-field
            v-model.trim="editedDealer.url"
            :rules="rules.url"
            validate-on-blur
            :label="$t('dealer.edit.url')"
            type="url"
            tabindex="1"
            clearable
          ></v-text-field>
          <v-text-field
            v-model.trim="editedDealer.emailAddress"
            :rules="rules.emailAddress"
            validate-on-blur
            :label="$t('dealer.edit.email')"
            type="email"
            tabindex="1"
            clearable
          ></v-text-field>
        </v-form>

        <v-btn
          v-text="$t('save')"
          :disabled="!isModelChanged()"
          class="d-btn-1 text-uppercase mt-8"
          width="120"
          depressed
          @click="saveChanges"
        ></v-btn>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import { dealerSchema } from '@/constants'

import objectUtil from '@/utils/object.util'

import pageMixin from '@/mixins/page.mixin'
import snackbarMixin from '@/mixins/snackbar.mixin'
import validationMixin from '@/mixins/validation.mixin'
import unsavedChangesMixin from '@/mixins/unsavedChanges.mixin'


export default {
  mixins: [
    pageMixin,
    snackbarMixin,
    validationMixin,
    unsavedChangesMixin
  ],


  data () {
    return {
      editedDealer: {},
      existingNames: []
    }
  },


  computed: {
    ...mapGetters('user', ['currentUser']),

    rulesToApply () {
      const rule = this.rule

      return {
        name: [
          rule.required(),
          rule.maxLength(dealerSchema.nameMaxLength),
          rule.unique(this.existingNames, this.$t('dealer.edit.nameExists'))
        ],
        phone: [rule.maxLength(dealerSchema.phoneMaxLength)],
        url: [rule.url(), rule.maxLength(dealerSchema.urlMaxLength)],
        emailAddress: [rule.required(), rule.email(), rule.maxLength(dealerSchema.emailMaxLength)]
      }
    }
  },


  methods: {
    ...mapActions('dealer', ['loadDealerById', 'updateDealerWithChainId']),

    async saveChanges () {
      const valid = await this.validate(this.rulesToApply)
      if (!valid || !this.isModelChanged()) return

      try {
        this.showSnackbarProcessing()
        await this.updateDealerWithChainId({ dealer: this.editedDealer, chainId: this.editedDealer.chainId })
        this.initWatchedModel(this.editedDealer)
        this.showSnackbarSuccess(this.$t('template.templatesUpdated'))
      } catch (error) {
        this.showSnackbarError()
        this.$log.error(error)
      }
    }
  },


  async created () {
    try {
      this.startPageLoading()
      const dealer = await this.loadDealerById({
        chainId: this.currentUser.chainId,
        id: this.currentUser.dealerId
      })
      this.editedDealer = objectUtil.getObjectCopy(dealer)
      this.initWatchedModel(this.editedDealer)
      this.resetValidation()
    } catch (error) {
      this.showSnackbarError()
      this.$log.error(error)
    } finally {
      this.stopPageLoading()
    }
  },

  beforeRouteLeave (to, from, next) {
    this.escape(next, () => next(false))
  }
}
</script>
